


























































































































































import Vue from 'vue';
import { recursiveFillData } from '@/helper/functions';
import { exportToPDF } from '@/helper/handle-pdf';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';
import popUpModal from '@/components/form/pop-up-modal.vue';
import watchers from '@/watchers/change-data-for-price-request/assembly-insurance/basic-tab';
import VersicherungsortForm from '@/views/components/assembly-insurance/versicherungsort-form.vue';
import VersicherungsortForm2 from '@/views/components/assembly-insurance/versicherungsort2-form.vue';

export default Vue.extend({
  name: 'assembly-insurance-basic',
  mixins: [dataChangeMixin],
  components: {
    popUpModal,
    VersicherungsortForm,
    VersicherungsortForm2,
  },
  data() {
    return {
      name: 'assembly-insurance-basic',
      key: 'ASSEMBLY_INSURANCE',
      vertragsart: '',
      modalOpen: false,
      modalBody: 'Achtung! Die Zeitspanne wurde auf dem Folgetab “Mon Tarifkalkulation” angepasst und stimmt nicht mehr mit dem Start- und Enddatum überein.',
      versicherungsort: {
        Strabe: '',
        PLZ: '',
        Ort: '',
        Nr: '',
        ZursZone: '',
        Starkregenzone: '',
        Ausland: '',
      },
      versicherungsort2: {
        Strabe: '',
        PLZ: '',
        Ort: '',
        Nr: '',
        ZursZone: '',
        Starkregenzone: '',
        Ausland: '',
        Funktion: '',
      },
      VorlagerungEingabe: '',
      Vorlagerung: '',
      montagebeginnVertragsbeginn: '',
      montageende: '',
      beginnDerErprobung: '',
      endeDerErprobung: '',
      ruhezeitenEingabe: '',
      ruhezeiten: '',
    };
  },
  props: ['insuranceKey', 'caseStatus', 'insuranceData', 'insuranceFormsApiData', 'processSavingData'],
  watch: {
    ...watchers,
    insuranceFormsApiData() {
      if (this.insuranceData === null) {
        this.fillApiValuesData();
      }
    },
  },
  methods: {
    updateVersicherungsort(newData: any) {
      this.versicherungsort = {
        ...newData,
      };
    },
    updateVersicherungsort2(newData: any) {
      this.versicherungsort2 = {
        ...newData,
      };
    },
    fillApiValuesData() {
      if (this.insuranceFormsApiData && this.insuranceFormsApiData.basicData) {
        this.vertragsart = this.insuranceFormsApiData.basicData.vertragsart;
        this.montagebeginnVertragsbeginn = this.insuranceFormsApiData.basicData.montagebeginnVertragsbeginn;
        this.montageende = this.insuranceFormsApiData.basicData.montageende;
        this.endeDerErprobung = this.insuranceFormsApiData.basicData.endeDerErprobung;
        recursiveFillData(this.versicherungsort, this.insuranceFormsApiData.basicData.versicherungsort);
        recursiveFillData(this.versicherungsort2, this.insuranceFormsApiData.basicData.versicherungsort2);
      }
    },
    setValues(basicData: any) {
      this.vertragsart = basicData.vertragsart;
      this.versicherungsort = basicData.versicherungsort;
      this.versicherungsort2 = basicData.versicherungsort2;
      this.Vorlagerung = basicData.Vorlagerung;
      this.VorlagerungEingabe = basicData.VorlagerungEingabe;
      this.montagebeginnVertragsbeginn = basicData.montagebeginnVertragsbeginn;
      this.montageende = basicData.montageende;
      this.beginnDerErprobung = basicData.beginnDerErprobung;
      this.endeDerErprobung = basicData.endeDerErprobung;
      this.ruhezeitenEingabe = basicData.ruhezeitenEingabe;
      this.ruhezeiten = basicData.ruhezeiten;
    },
    onSubmit(goNextPage = true) {
      const data = {
        vertragsart: this.vertragsart,
        versicherungsort: this.versicherungsort,
        versicherungsort2: this.versicherungsort2,
        Vorlagerung: this.Vorlagerung,
        VorlagerungEingabe: this.VorlagerungEingabe,
        montagebeginnVertragsbeginn: this.montagebeginnVertragsbeginn,
        montageende: this.montageende,
        beginnDerErprobung: this.beginnDerErprobung,
        endeDerErprobung: this.endeDerErprobung,
        ruhezeitenEingabe: this.ruhezeitenEingabe,
        ruhezeiten: this.ruhezeiten,
      };
      if (goNextPage) {
        this.$emit('basic-data-change', data, this.key);
      } else {
        this.$emit('basic-data-change', data, this.key, 1);
      }
    },
    downloadPDF() {
      exportToPDF.call(this, 'Zusammenfassung-Montageversicherung-Grunddaten.pdf');
    },
    savePdf() {
      // save insurance, get id and make pdf
      this.$emit('add-to-queue', 'saveBasicPDF', '');
      this.onSubmit(false);
    },
    openModal() {
      this.modalOpen = !this.modalOpen;
    },
    modalClose() {
      this.modalOpen = false;
    },
  },
  mounted() {
    (this as any).resetWatcherActivated();
    this.$emit('on-key-change', this.key);

    if (this.insuranceData && this.insuranceData.basicData) {
      this.setValues(this.insuranceData.basicData);
    }
    (this as any).startWatcherActivated();
  },
  computed: {
    dauerDerMontageGesamtChanged(): boolean {
      return this.insuranceData?.spreadsheetData?.montageversicherung?.dauerDerMontageGesamtChanged;
    },
    dauerDesProbebetriebsGesamtChanged(): boolean {
      return this.insuranceData?.spreadsheetData?.montageversicherung?.dauerDesProbebetriebsGesamtChanged;
    },
  },
});
