import { WatchOptions } from 'vue';

export default {
  'versicherungsort.Ausland': function (newVal: any, oldVal: any) {
    // eslint-disable-next-line no-underscore-dangle
    if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
      this.$emit('change-data-for-price-request', true);
    }
  },
  'versicherungsort.ZursZone': function (newVal: any, oldVal: any) {
    // eslint-disable-next-line no-underscore-dangle
    if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
      this.$emit('change-data-for-price-request', true);
    }
  },
} as Record<string, (newVal: any, oldVal: any, options?: WatchOptions) => void>;
