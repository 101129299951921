

















































































































import Vue from 'vue';
import ZuersZone from '@/components/form/zuers-zone.vue';
import { mapGetters, mapMutations } from 'vuex';
import zuersZoneModule from '@/store/modules/zuersZone';

export default Vue.extend({
  name: 'Versicherungsort2Form',
  components: {
    ZuersZone,
  },
  props: {
    versicherungsort2: {
      type: Object as () => {
        Strabe: string;
        PLZ: string;
        Ort: string;
        Nr: string;
        ZursZone: string;
        Starkregenzone: string;
        Ausland: string;
        Funktion: string;
      },
      required: true,
    },
  },
  data() {
    return {
      localVersicherungsort: {
        ...this.versicherungsort2,
      },
      modalOpen: false,
      moduleNamespace: 'zuersZoneInstance2',
    };
  },
  watch: {
    versicherungsort2: {
      handler(newVal) {
        this.localVersicherungsort = {
          ...newVal,
        };
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations('zuersZoneInstance2', {
      updatePlace: 'SET_PLACE',
      updatePostalCode: 'SET_POSTAL_CODE',
      updateStreet: 'SET_STREET',
      updateHouseNumber: 'SET_HOUSE_NUMBER',
      updateZurs: 'SET_ZURS',
      resetAddres: 'RESET_STATE',
    }),
    setZuersAddressToStore() {
      this.resetAddres();
      this.updatePlace(this.versicherungsort2.Ort);
      this.updatePostalCode(this.versicherungsort2.PLZ);
      this.updateStreet(this.versicherungsort2.Strabe);
      this.updateHouseNumber(this.versicherungsort2.Nr);
      this.updateZurs(this.versicherungsort2.ZursZone);
    },
    emitChanges() {
      this.$emit('update:versicherungsort2', this.localVersicherungsort);
    },
  },
  created() {
    const store = this.$store as any;
    // eslint-disable-next-line no-underscore-dangle
    if (!store._modulesNamespaceMap['zuersZoneInstance2/']) {
      store.registerModule('zuersZoneInstance2', {
        ...zuersZoneModule,
        namespaced: true,
      });
    }

    this.$watch(
      () => this.$store.getters['zuersZoneInstance2/getZurs'],
      (newVal) => {
        if (newVal !== undefined) {
          this.localVersicherungsort.ZursZone = newVal;
          this.localVersicherungsort.Starkregenzone = newVal;
          this.emitChanges();
        }
      },
    );
  },
  beforeDestroy() {
    const store = this.$store as any;
    // eslint-disable-next-line no-underscore-dangle
    if (store._modulesNamespaceMap['zuersZoneInstance2/']) {
      store.unregisterModule('zuersZoneInstance2');
    }
  },
  mounted() {
    this.setZuersAddressToStore();
  },
});
